/* footer {
  background-color: #a2121f;
  font-size: 0.7rem;
}

.footer__bg-img {
  background-image: url("/images/page-bg-bottom.png");
}

body {
  background-color: #F8F7F7;
}

.dark-bg {
  background-color: #1F1F1F;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.55;
}

.divider, .btn-first, .btn-second, h3, h4, h5 {
  visibility: hidden;
}

.btn-first {
  padding-left: 2.65rem;
  padding-right: 2.65rem;
}

.btn-second {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

#principal {
  background-image: url("/images/image-wide-3.jpg");
  background-size: cover;
}
#principal h5, #principal h4 {
  font-family: "Raleway", sans-serif;
}

#rodape {
  font-size: 0.7rem;
  position: absolute;
  z-index: 0;
  bottom: 0;
}

@media (max-width: 800px) {
  #principal #home h5 {
    font-size: 20px;
  }

  #principal #home h3 {
    font-size: 30px;
  }

  #principal #home h4 {
    font-size: 15px;
    text-align: center;
    line-break: auto;
  }

  #principal #home #registro {
    flex-direction: column;
    align-items: center;
  }

  #principal #home #registro #cadastrar {
    margin-right: 0px !important;
  }

  #principal #home #registro #cadastrar, #principal #home #registro #ou,
#principal #home #registro #entrar, #principal #home #registro #contato {
    font-size: 10px;
  }

  #rodape {
    font-size: 10px;
  }
} */
html,body{
    min-height: 100vh;
}
body{
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
li{
    list-style: none;
}
header{
    display: flex;
    justify-content: space-between;
    padding: 1% 0;
    width: 85%;
    margin: 0 auto;
}
header nav{
    display: flex;
}
.nav-btns{
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.nav-btns a, .col-btn .btn-inscrever{
    margin: 0 7px;
    background-color: #3030D0;
    color: #fff !important;
    border-radius: 4px;
}
.col-btn{
    display: flex;
    align-items: center;
}
.btn-inscrever{
    width: 100%;
    z-index: 20;
    margin: 0 auto;
}
.nav-btns a:hover, .col-btn .btn-inscrever:hover{
    background-color: #0000ff;
    color: #fff;
}
.nav-btns li{
    list-style: none;
    font-family: 'Raleway', sans-serif;
    font-size: 15px ;
}
.btn-laranja{
    background-color: #FF4B00 !important;
    // width: 70% !important;
    margin: 0 !important;
}
.btn-laranja:hover{
    background-color: #ff2200 !important;
}
main{
    margin: 0 auto;
    width: 85%;
    flex: 1 0 auto;
}
.banner-home{
    background-color: #e8e8ff;
    border-radius: 12px;
    color: #fff;
    object-fit: cover;
    background: cover;
    // background: url('../images/banner-main.png') no-repeat center;
    margin: 0 auto;
    height: 258px;
    padding: 15px 125px;
}
.banner-home h1{
    font-size: 56px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
}
.banner-home p{
    font-size: 32px;
    font-family: 'Raleway', sans-serif;
}
.cursos-abertos .row-descricao{
    margin: 0 auto;
    width: 90%;
}
.card h4{
    margin: 15px 0;
    padding: 5px 20px;
    font-size: 31px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}
.card h4, .card a{
    color: #3030D0 !important;
    font-family: 'Raleway', sans-serif;
}
.cursos-abertos{
    margin-top: 45px;
}
.cursos-abertos h2{
    font-size: 35px;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
}
/* .cursos-abertos .uk-panel{
    padding: 0 12px 40px 12px;
} */
.cursos-abertos .uk-grid{
    padding: 0;
}
.cursos-abertos ul{
    margin-bottom: 10px;
    padding: 0 10px;
    font-size: 18px;
}
.uk-slider-items{
    display: flex;
    align-items: center;
}
.cursos-abertos ul li{
    padding-bottom: 3px;
    font-family: 'Raleway', sans-serif;
}
/* .cursos-abertos .uk-position-relative{
    padding-bottom: 40px;
} */
.cursos-abertos .card{
    margin: 15px;
    border: none;
    color: #3030D0;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 6px;
    min-height: 240px;
    transition: .4s all ease-in-out;
    border: 1px solid #f0f0f0;
    background-color: #fbfbfb;
}
.cursos-abertos .card:hover{
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.cursos-abertos .card .row{
    bottom: 25px;
}
.link-modalidade:hover{
    cursor: default;
    text-decoration: none;
}
.span-modalidade{
    margin-left: 20px;
    background-color: #00DEB5;
    border-radius: 15px;
    padding: 1px 10px 1px;
    color: #3030D0 !important;
    text-decoration: none;

}
.link-passar-carousel{
    display: block;
    visibility: visible;
}
.link-passar-carousel svg{
    color: #0000ff !important;
}
/* Footer */
footer{
    max-width: 100%;
}
footer p{
    width: 100%;
    text-align: center;
    font-family: 'Raleway', sans-serif;
}
footer img{
    padding-bottom: 10px;
}
@media (max-width: 730px) {
    header{
        flex-direction: column;
        width: 100%;
    }
    .logo{
        margin: 0 auto;
    }
    header nav{
        display: flex;
        margin: 0 auto;
    }
    .nav-btns{
        padding: 10px 0;
    }
    .nav-btns a{
        font-size: 15px;
    }
    .banner-home{
        padding: 4px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .banner-home h1{
        font-size: 32px;
        font-family: 'Raleway', sans-serif;
    }
    .banner-home p{
        font-size: 26px;
        font-family: 'Raleway', sans-serif;
        max-width: 100%;
    }
    .cursos-abertos h2{
        text-align: center;
        font-family: 'Raleway', sans-serif;
        margin-bottom: 0;
    }
    .cursos-abertos .uk-grid{
        padding: 0;
    }
    footer{
        padding-bottom: 20px;
    }
    footer .col-md-4{
        display: flex;
        justify-content: center;
    }
}
//  Modal

#modal-center{
    background-color: rgba(0, 0, 0, 0.5);
}
.uk-modal-body{
    padding: 0 !important;
    border-radius: 20px;
    width: 800px !important;
}
.titulo-modal{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 34px !important;
    border: 2px solid #3030D0;
    background-color: #3030D0;
    color: #fff;
    padding: 20px 30px;

}
.descricao-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.descricao-modal h3{
    font-size: 29px;
    font-weight: 400;
}
.btn-modal{
    min-width: 70%;
    margin: 0 auto;
    color: #fff !important;
}
.detalhes-modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.detalhe-modal{
    color: #fff;
    border-radius: 15px;
    background-color: #00DEB5;
    min-width: 80%;
    padding: 10px 15px;
    font-size: 24px;
    margin-bottom: 20px;
}
.btn-modal-cel{
    display: none !important;
}
.btn-modal-comp{
    display: block !important;
}
@media (max-width: 730px) {
    .btn-modal-comp{
        display: none !important;
    }
    .btn-modal-cel{
        display: block !important;
        width: 100%;
    }
}
